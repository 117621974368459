<template>

<!-- JOYSTICK -->
  <div class="joystick-wrapper">
    <JoyStick
      :isCircular="block.circular"
      @leverchange="UpdateLever($event)"
      @touchStart="TouchStarted"
      @touchFinish="TouchFinished"
      :debug="$parent.ShowDebugger"/>
  </div>

</template>
<script>

import JoyStick from '@/components/externals/JoyStick.vue';
import { roundTo } from "@/components/utils.js";

export default {
  components: {
    JoyStick
  },
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxDecimals: 2,
      EmitCoolingDown: false,
      joystickData: {
        x: 0,
        y: 0,
        speed: 0,
        angle: 0,
        radians: 0,
      },
    };
  },
  computed: {},
  methods: {
    TouchStarted(){
      this.$parent.scrollDisabled = true
      // Envio pressed
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'joystick',
        name: this.block.blockName,
        input:{
          type: 'pressed',
          pressed: true
        }
      })
    },
    TouchFinished(){
      this.$parent.scrollDisabled = false
      // Envio pressed
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'joystick',
        name: this.block.blockName,
        input:{
          type: 'pressed',
          pressed: false
        }
      })
    },
    UpdateLever({ speed, angle, radians }) {
      //Copia los datos del joystick del componente hijo
      const joy = this.joystickData
      joy.speed = speed
      joy.angle = angle
      joy.radians = radians

      if (this.EmitCoolingDown && this.block.circular) return  // cooldown
      if (this.EmitCoolingDown && !this.block.circular && joy.angle != 0) return  // cooldown

      // Envia los datos a la screen
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'joystick',
        name: this.block.blockName,
        input:{
          type: this.block.circular ? 'circular' : 'lever',
          // joy.speed llega a maximo .75. Para no meter en codigo interno de eso que se usa para estilo tambien
          // Multiplico por 1.3333 para mapearlo a 0-1
          distance: roundTo(joy.speed * 1.3333333, this.maxDecimals), 
          radians: roundTo(joy.radians, this.maxDecimals),
          angle: roundTo(joy.angle, this.maxDecimals),
        }
      })

      this.EmitCoolingDown = true

      setTimeout(() => {
        this.EmitCoolingDown = false
      }, this.block.dataInterval)

    },
  },
  mounted() {
    //
  },
};
</script>
